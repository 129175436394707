import React from 'react'
import { MicroFrontEnd, useEntitlements } from 'properties-ui-common'
import { useParams } from 'react-router-dom'
import { mfeNavUrls } from '../utility/url'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { apiConfig } from '../../../apiConfig'
export const ItemDetailsDashboard = () => {
  const { id } = useParams<string>()
  const { entitlements } = useEntitlements()
  return (
    <Grid.Container>
      <Grid.Item xs={7}>
        <MicroFrontEnd
          name="IndirectItemDetailsNri"
          src={apiConfig.microFrontEnds.IndirectItemDetailsNri}
          props={{
            mfeNavUrls,
            tcinKey: id,
          }}
          mode={MicroFrontEnd.Mode.Read}
        />
      </Grid.Item>
      <Grid.Item xs={5}>
        <Grid.Container>
          <Grid.Item xs={12}>
            <MicroFrontEnd
              name="IndirectItemDocuments"
              src={apiConfig.microFrontEnds.IndirectItemDocuments}
              props={{
                mfeNavUrls,
                tcinKey: id,
              }}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <MicroFrontEnd
              name="IndirectItemRelatedItems"
              src={apiConfig.microFrontEnds.IndirectItemRelatedItems}
              props={{
                mfeNavUrls,
                tcinKey: id,
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12}>
        {(entitlements?.sourcing?.read || entitlements?.ttsAdmin?.create) && (
          <MicroFrontEnd
            name="HznItemDetails"
            src={apiConfig?.microFrontEnds.HznItemDetails + '/mfe.js'}
            props={{
              tcinKey: id,
            }}
          />
        )}
      </Grid.Item>
      <Grid.Item xs={12}>
        {entitlements?.catItem?.read && (
          <MicroFrontEnd
            name="HznItemCatalogs"
            src={apiConfig?.microFrontEnds.HznItemCatalogs + '/mfe.js'}
          />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default ItemDetailsDashboard
